import { IUser } from '@/types/user';

let user: IUser | null = null;

const UserStore = {
  set: (usrObj: IUser | null) => (user = usrObj),
  get: () => user,
};

Object.freeze(UserStore);
export default UserStore;

export const hasSessionCookie = (cookie: string | undefined) => {
  if (!cookie) {
    return false;
  }

  return cookie.includes('connect.sid=');
};
