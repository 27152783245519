'use client';

import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';
import { useToaster } from '@/hooks/useToaster';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { expand } = useToaster();

  const { theme = 'system' } = useTheme();
  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      toastOptions={{
        style: {
          fontFamily: 'Lato, sans-serif',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        duration: 7000,
      }}
      expand={expand}
      {...props}
    />
  );
};

export { Toaster };
