import type { Config } from 'tailwindcss';

const config = {
  darkMode: ['class'],
  content: [
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
    './hooks/**/*.{ts,tsx}',
  ],
  prefix: '',
  theme: {
    screens: {
      sm: '576px',
      // => @media (min-width: 0px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
    },
    colors: {
      azure: {
        100: 'hsl(var(--azure-100))',
        400: 'hsl(var(--azure-400))',
        500: 'hsl(var(--azure-500))',
        600: 'hsl(var(--azure-600))',
        700: 'hsl(var(--azure-700))',
        800: 'hsl(var(--azure-800))',
        900: 'hsl(var(--azure-900))',
        1000: 'hsl(var(--azure-1000))',
      },
      neutral: {
        0: 'hsl(var(--neutral-0))',
        100: 'hsl(var(--neutral-100))',
        200: 'hsl(var(--neutral-200))',
        300: 'hsl(var(--neutral-300))',
        400: 'hsl(var(--neutral-400))',
        500: 'hsl(var(--neutral-500))',
        600: 'hsl(var(--neutral-600))',
        700: 'hsl(var(--neutral-700))',
        800: 'hsl(var(--neutral-800))',
        900: 'hsl(var(--neutral-900))',
        1000: 'hsl(var(--neutral-1000))',
      },
      tropicalIndigo: {
        100: 'hsl(var(--tropicalIndigo-100))',
        400: 'hsl(var(--tropicalIndigo-400))',
        500: 'hsl(var(--tropicalIndigo-500))',
        600: 'hsl(var(--tropicalIndigo-600))',
        700: 'hsl(var(--tropicalIndigo-700))',
        800: 'hsl(var(--tropicalIndigo-800))',
        900: 'hsl(var(--tropicalIndigo-900))',
        1000: 'hsl(var(--tropicalIndigo-1000))',
      },
      electricBlue: {
        100: 'hsl(var(--electricBlue-100))',
        400: 'hsl(var(--electricBlue-400))',
        500: 'hsl(var(--electricBlue-500))',
        600: 'hsl(var(--electricBlue-600))',
        700: 'hsl(var(--electricBlue-700))',
        800: 'hsl(var(--electricBlue-800))',
        900: 'hsl(var(--electricBlue-900))',
        1000: 'hsl(var(--electricBlue-1000))',
      },
      pumpkin: {
        100: 'hsl(var(--pumpkin-100))',
        400: 'hsl(var(--pumpkin-400))',
        500: 'hsl(var(--pumpkin-500))',
        600: 'hsl(var(--pumpkin-600))',
        700: 'hsl(var(--pumpkin-700))',
        800: 'hsl(var(--pumpkin-800))',
        900: 'hsl(var(--pumpkin-900))',
        1000: 'hsl(var(--pumpkin-1000))',
      },
      cinnabarDanger: {
        100: 'hsl(var(--cinnabarDanger-100))',
        400: 'hsl(var(--cinnabarDanger-400))',
        500: 'hsl(var(--cinnabarDanger-500))',
        600: 'hsl(var(--cinnabarDanger-600))',
        700: 'hsl(var(--cinnabarDanger-700))',
        800: 'hsl(var(--cinnabarDanger-800))',
        900: 'hsl(var(--cinnabarDanger-900))',
        1000: 'hsl(var(--cinnabarDanger-1000))',
      },
      veridianSuccess: {
        100: 'hsl((var(--veridianSuccess-100))',
        400: 'hsl((var(--veridianSuccess-400))',
        500: 'hsl((var(--veridianSuccess-500))',
        600: 'hsl((var(--veridianSuccess-600))',
        700: 'hsl((var(--veridianSuccess-700))',
        800: 'hsl((var(--veridianSuccess-800))',
        900: 'hsl((var(--veridianSuccess-900))',
        1000: 'hsl(var(--veridianSuccess-1000))',
      },
      harvestGoldWarning: {
        100: 'hsl(var(--harvestGoldWarning-100))',
        400: 'hsl(var(--harvestGoldWarning-400))',
        500: 'hsl(var(--harvestGoldWarning-500))',
        600: 'hsl(var(--harvestGoldWarning-600))',
        700: 'hsl(var(--harvestGoldWarning-700))',
        800: 'hsl(var(--harvestGoldWarning-800))',
        900: 'hsl(var(--harvestGoldWarning-900))',
        1000: 'hsl(var(--harvestGoldWarning-1000))',
      },
      pinkPulse: {
        main: 'hsl(var(--pinkPulse-main))',
        light: 'hsl(var(--pinkPulse-light))',
      },
      scoutBoundary: {
        main: 'hsl(var(--scoutBoundary-main))',
        light: 'hsl(var(--scoutBoundary-light))',
      },
      scoutFrame: {
        main: 'hsl(var(--scoutFrame-main))',
        light: 'hsl(var(--scoutFrame-light))',
      },
      scoutTrace: {
        main: 'hsl(var(--scoutTrace-main))',
        light: 'hsl(var(--scoutTrace-light))',
      },
      border: 'hsl(var(--border))',
      input: 'hsl(var(--input))',
      ring: 'hsl(var(--ring))',
      background: 'hsl(var(--background))',
      foreground: 'hsl(var(--foreground))',
      primary: {
        DEFAULT: 'hsl(var(--primary))',
        foreground: 'hsl(var(--primary-foreground))',
      },
      secondary: {
        DEFAULT: 'hsl(var(--neutral-800))',
        foreground: 'hsl(var(--neutral-0))',
      },
      destructive: {
        DEFAULT: 'hsl(var(--destructive))',
        foreground: 'hsl(var(--destructive-foreground))',
      },
      muted: {
        DEFAULT: 'hsl(var(--muted))',
        foreground: 'hsl(var(--muted-foreground))',
      },
      accent: {
        DEFAULT: 'hsl(var(--accent))',
        foreground: 'hsl(var(--accent-foreground))',
      },
      popover: {
        DEFAULT: 'hsl(var(--popover))',
        foreground: 'hsl(var(--popover-foreground))',
      },
      card: {
        DEFAULT: 'hsl(var(--card))',
        foreground: 'hsl(var(--neutral-0))',
      },
      current: 'currentColor',
      transparent: 'transparent',
    },
    extend: {
      borderRadius: {
        lg: 'var(--radius)',
        md: 'calc(var(--radius) - 2px)',
        sm: 'calc(var(--radius) - 4px)',
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        slideIn: {
          '0%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(-100%)' },
        },
        expand: {
          '0%': { scale: '0', opacity: '0' },
          '100%': { scale: '1', opacity: '1' },
        },
        pulseBeat: {
          '0%': { boxShadow: '0 0 0 0 rgba(255, 255, 255, .9)' },
          '70% ': { boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)' },
        },
        fadeIn: {
          '0%': { opacity: '0' },
          '100% ': { opacity: '1' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        slideIn: 'slideIn 0.5s ease-in-out forwards',
        expand: 'expand 0.2s ease-out',
        pulseBeat: 'pulseBeat 1.5s infinite',
        fadeIn: 'fadeIn 0.4s ease',
      },
    },
  },
  plugins: [
    require('tailwindcss-animate'),
    require('@tailwindcss/aspect-ratio'),
  ],
} satisfies Config;

export default config;
