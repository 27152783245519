import { BasicSeoType, SeoType } from '@/types/seo';

export const defaultSeo: SeoType = {
  title: 'Hivemapper - Build a Decentralized Global Map',
  description:
    "Hivemapper is building a decentralized global map of the world. Join our network and take part in building a map of the world that's accessible to everyone.",
  keywords:
    'Hivemapper Contribute, Drive to Earn, Map AI Training Games, HONEY Rewards, Community Mapping, Global Map Data, Fleet Mapping Solutions, Drive and Earn, AI-Powered Mapping, Real-Time Map Updates',
  image:
    'https://hivemapper-marketing-public.s3-us-west-2.amazonaws.com/social-media-card.jpg',
};

const imagePrefix =
  'https://hivemapper-static.s3.us-west-2.amazonaws.com/website/public/seo/';

const imageFormat = '.jpg';

export const seo: BasicSeoType = {
  '/': {
    title: 'Hivemapper Mapping Network',
    description: 'Welcome to the Hivemapper Mapping Network, a decentralized global map.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper' + imageFormat,
  },
  '/account': {
    title: 'Hivemapper: Account',
    description: 'Access support for your Hivemapper account.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_account' + imageFormat,
  },
  '/account/profile': {
    title: 'Hivemapper: My Account',
    description: 'View and manage your Hivemapper account.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_account' + imageFormat,
  },
  '/account/support': {
    title: 'Hivemapper: Account Support',
    description: 'Access support for your Hivemapper account.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_account-support' + imageFormat,
  },
  '/console/developers/api-key': {
    title: 'Map Data Console: Developers - API Key',
    description: 'Manage your Hivemapper data products, generate API keys, purchase map credits and monitor your API usage - all in one central hub.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_console-developers-apikey' + imageFormat,
  },
  '/console/developers/usage': {
    title: 'Map Data Console: Developers - Usage',
    description: 'Manage your Hivemapper data products, generate API keys, purchase map credits and monitor your API usage - all in one central hub.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_console-developers-usage' + imageFormat,
  },
  '/console/organization/info': {
    title: 'Map Data Console: Organization - Info',
    description: 'Manage your Hivemapper data products, generate API keys, purchase map credits and monitor your API usage - all in one central hub.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_console-organization-info' + imageFormat,
  },
  '/console/organization/users': {
    title: 'Map Data Console: Organization - Users',
    description: 'Manage your Hivemapper data products, generate API keys, purchase map credits and monitor your API usage - all in one central hub.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_console-organization-users' + imageFormat,
  },
  '/login': {
    title: 'Hivemapper: Log In',
    description: 'Secure login page for Hivemapper - a decentralized global map.',
    keywords: defaultSeo.keywords,
  },
  '/network/ai-trainers-reviews': {
    title: 'Hivemapper: AI Trainers Reviews',
    description: "Explore the Hivemapper Network's metrics of global AI Trainers reviews.",
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_aitrainers' + imageFormat,
  },
  '/network/contributors': {
    title: 'Hivemapper: Contributors',
    description: "Explore Hivemapper Network's metrics of global contributors.",
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_network-contributors' + imageFormat,
  },
  '/network/coverage': {
    title: 'Hivemapper: Network Coverage',
    description: "Explore the Hivemapper Network's global coverage.",
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_network-coverage' + imageFormat,
  },
  '/privacy/overview': {
    title: 'Privacy Overview',
    description: "Privacy is at the core of the Hivemapper Network's mission.",
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_privacy' + imageFormat,
  },
  '/privacy/privacy-policy': {
    title: 'Privacy Policy',
    description: "Privacy is at the core of the Hivemapper Network's mission.",
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_privacy-policy' + imageFormat,
  },
  '/privacy/redaction-request': {
    title: 'Redaction Request',
    description: 'Hivemapper can assist with blurring or deletion of images collected by the network.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_privacy-redactionrequest' + imageFormat,
  },
  '/product': {
    title: 'Map Data Products',
    description: 'Hivemapper APIs and tools offer fresh street-level map imagery and data to enhance navigation, automotive, logistics, and government sectors.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products' + imageFormat,
  },
  '/product/bursts': {
    title: 'Bursts: On-Demand, Targeted Map Data',
    description: 'Hivemapper Bursts provide on-demand, targeted map data collection. Our global mapping network will quickly and cost-effectively collect the data you need.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-bursts' + imageFormat,
  },
  '/product/map-features-api': {
    title: 'Map Features API: Access Geolocated Traffic Infrastructure',
    description: 'The Map Features API provides precisely positioned map features, including speed signs, turn restrictions, stop signs, highway exits, and traffic lights.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-mapfeaturesapi' + imageFormat,
  },
  '/product/map-image-api': {
    title: 'Map Image API: Access High-Quality Street-Level Imagery',
    description: 'Hivemapper’s Map Image API connects your business to fresh, high-quality, and standardized street-level imagery collected by Hivemapper’s global mapping network.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-mapimageapi' + imageFormat,
  },
  '/product/pricing': {
    title: 'Pricing: Map Data Products',
    description: 'Hivemapper APIs and tools offer fresh street-level map imagery and data to enhance navigation, automotive, logistics, and government sectors.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-pricing' + imageFormat,
  },
  '/product/scout': {
    title: 'Scout: Advanced Location Monitoring',
    description: 'Scout is a powerful location-monitoring tool offering fresh, high-quality imagery and customizable UI options for diverse applications.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-scout' + imageFormat,
  },
  '/scout': {
    title: 'Scout: Advanced Location Monitoring',
    description: 'Scout is a powerful location-monitoring tool offering fresh, high-quality imagery and customizable UI options for diverse applications.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_scout' + imageFormat,
  },
  '/scout/list': {
    title: 'Scout: Advanced Location Monitoring',
    description: 'Scout is a powerful location-monitoring tool offering fresh, high-quality imagery and customizable UI options for diverse applications.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-scout-listview' + imageFormat,
  },
  '/scout/location': {
    title: 'Scout: Advanced Location Monitoring',
    description: 'Scout is a powerful location-monitoring tool offering fresh, high-quality imagery and customizable UI options for diverse applications.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-scout-locationview' + imageFormat,
  },
  '/scout/map': {
    title: 'Scout: Advanced Location Monitoring',
    description: 'Scout is a powerful location-monitoring tool offering fresh, high-quality imagery and customizable UI options for diverse applications.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_products-scout-mapview' + imageFormat,
  },
  '/tos/hivemapper-dashcam': {
    title: 'Terms of Service - Hivemapper Dashcam',
    description: 'Find terms of service and policies for Hivemapper products and services.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_tos-hivemapperdashcam' + imageFormat,
  },
  '/tos/pre-orders': {
    title: 'Terms of Service - Hivemapper Dashcam Pre-Orders',
    description: 'Find terms of service and policies for Hivemapper products and services.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_tos-preorders' + imageFormat,
  },
  '/tos/hivemapper-dashcam-terms': {
    title: 'Terms of Service - Hivemapper Dashcam',
    description: 'Find terms of service and policies for Hivemapper products and services.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_tos-hivemapperdashcam' + imageFormat,
  },
  '/tos/map-products': {
    title: 'Terms of Service - Map Products',
    description: 'Find terms of service and policies for Hivemapper products and services.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_tos-mapproducts' + imageFormat,
  },
  '/tos/mapping-network': {
    title: 'Terms of Service - Mapping Network',
    description: 'Find terms of service and policies for Hivemapper products and services.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_tos-mappingnetwork' + imageFormat,
  },
  '/trainers': {
    title: 'Train Map AI',
    description: 'Earn HONEY rewards while training Map AI in helping build the world\'s freshest map.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_aitrainers' + imageFormat,
  },
  '/use-cases': {
    title: 'Use Cases',
    description: 'Hivemapper APIs and tools offer fresh street-level map imagery and data to enhance navigation, automotive, logistics, and government sectors.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_usecases' + imageFormat,
  },
  '/use-cases/automotive': {
    title: 'Fresh Map Data for Autonomous Driving & ADAS',
    description: 'Hivemapper APIs empower automotive firms with accurate and dynamic data for autonomous vehicles, ADAS, and AI/machine learning.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_usecases-automotive' + imageFormat,
  },
  '/use-cases/government-city': {
    title: 'Map Solutions for City and Government Agencies',
    description: 'Hivemapper provides data-driven APIs and map tools to help city and government organizations manage urban planning and infrastructure.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_usecases-governmentandcity' + imageFormat,
  },
  '/use-cases/insurance': {
    title: 'Detailed Map Imagery and Data for Insurance',
    description: 'Hivemapper provides powerful map data APIs and tools for insurance risk assessments, property valuations, claims investigations, and more.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_usecases-insurance' + imageFormat,
  },
  '/use-cases/navigation-logistics': {
    title: 'Map Data Tools for Navigation & Logistics',
    description: 'Upgrade navigation and logistics services with fresh, accurate map data. Enhance route planning, regulatory compliance, and operational efficiency.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_usecases-navigationandlogistics' + imageFormat,
  },
  '/use-cases/real-estate': {
    title: 'Fresh Map Imagery for Real Estate',
    description: 'Hivemapper APIs and products provide dynamic insights for real estate developers, investors, brokers, and agents to analyze markets, assess properties, and more.',
    keywords: defaultSeo.keywords,
    image: imagePrefix + 'hivemapper_usecases-realestate' + imageFormat,
  },
};

