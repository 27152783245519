import { useEffect, memo } from 'react';
import { init, setTag } from '@sentry/react';
import { useUser } from '@/hooks/useUser';

function Sentry() {
  const { user } = useUser();
  const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DNS_CLIENT;

  useEffect(() => {
    if (sentryDsn) {
      init({
        dsn: sentryDsn,
      });

      if (user) {
        setTag('sessionID', user.sessionID);
        setTag('userId', user.username);
      }
    }
  }, [user, sentryDsn]);

  return <></>;
}

export default memo(Sentry);
