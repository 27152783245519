import {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

interface IContext {
  expand: boolean;
  setExpand: Dispatch<SetStateAction<boolean>>;
}

interface UserProps {
  children: React.ReactNode;
}

const ToasterContext = createContext<IContext | null>(null);

const ToasterProvider: React.FC<UserProps> = ({ children }) => {
  const [expand, setExpand] = useState(false);

  return (
    <ToasterContext.Provider value={{ expand, setExpand }}>
      {children}
    </ToasterContext.Provider>
  );
};

const useToaster = () => {
  const context = useContext(ToasterContext);
  if (!context) {
    throw new Error('useToaster must be used within the ToasterProvider');
  }

  return context;
};

export { ToasterProvider, useToaster };
