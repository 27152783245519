import {
  useState,
  createContext,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import { completeStripeCheckout, expireStripeCheckout } from '@/api/stripe';
import * as Toast from '@/components/ui/toast/Toast';
import { useUser } from './useUser';

interface IContext {
  mapCredits: number;
  setMapCredits: Dispatch<SetStateAction<number>>;
  creditsModalOptions: CreditsModalOptions;
  setCreditsModalOptions: Dispatch<SetStateAction<CreditsModalOptions>>;
}

interface UserProps {
  children: React.ReactNode;
}

interface CreditsModalOptions {
  isVisible: boolean;
  redirectionUrl?: string;
}

const MapCreditContext = createContext<IContext | null>(null);

const MapCreditProvider: React.FC<UserProps> = ({ children }) => {
  const { user } = useUser();
  const [mapCredits, setMapCredits] = useState<number>(user?.apiCredits || 0);
  const [creditsModalOptions, setCreditsModalOptions] =
    useState<CreditsModalOptions>({
      isVisible: false,
    });

  const router = useRouter();

  useEffect(() => {
    const { query } = router;
    if (query) {
      const success = query.success;
      const cancel = query.cancel;
      const sessionId = query.session_id as string;

      if (success && sessionId) {
        const tryCompleteStripeCheckout = async () => {
          const response = await completeStripeCheckout(sessionId);

          if ('error' in response || !response.credits) {
            toast.custom(t => (
              <Toast.Default
                title="Try Again"
                description="Map Credits purchase was unsuccessful. Please try again."
                buttons={[
                  {
                    label: 'Dismiss',
                    onClick: () => toast.dismiss(t),
                    variant: 'secondary',
                    size: 'fw',
                  },
                ]}
              />
            ));
          }

          setMapCredits(response.credits);
          toast.custom(t => (
            <Toast.Default
              title="Balance Updated"
              description="Map Credits were successfully added to your account."
              buttons={[
                {
                  label: 'Dismiss',
                  onClick: () => toast.dismiss(t),
                  variant: 'secondary',
                  size: 'fw',
                },
              ]}
            />
          ));
        };

        tryCompleteStripeCheckout();
      }

      if (cancel && sessionId) {
        const tryExpireStripeCheckout = async () => {
          const response = await expireStripeCheckout(sessionId);

          if ('error' in response || !response.success) {
            console.log(response.error);
          }
        };

        tryExpireStripeCheckout();
      }
    }
  }, []);

  useEffect(() => {
    if (user?.apiCredits) {
      setMapCredits(user?.apiCredits);
    }
  }, [user?.apiCredits]);

  return (
    <MapCreditContext.Provider
      value={{
        mapCredits,
        setMapCredits,
        creditsModalOptions,
        setCreditsModalOptions,
      }}>
      {children}
    </MapCreditContext.Provider>
  );
};

const useMapCredits = () => {
  const context = useContext(MapCreditContext);
  if (!context) {
    throw new Error('useMapCredits must be used within the MapCreditProvider');
  }

  return context;
};

export { MapCreditProvider, useMapCredits };
