import {
  LngLatBoundsLike,
  LngLatLike,
  Map,
  PointLike,
  ResourceType,
} from 'maplibre-gl';
import { NextRouter } from 'next/router';
import {
  isMapboxURL,
  transformMapboxUrl,
} from 'maplibregl-mapbox-request-transformer';
import * as GeoLib from 'geolib';
import * as turf from '@turf/turf';
import { IMapType, IPoint, IPoints } from '@/types/map';
import { ScoutLocation } from '@/types/location';

export const getMapboxAccessToken = () => {
  return `${process.env.NEXT_PUBLIC_MAPBOX_TOKEN}`;
};

export const getAppleMapsAccessToken = () => {
  return `${process.env.NEXT_PUBLIC_APPLE_TOKEN}`;
};

export const getMapboxStyleUrl = () => {
  return 'mapbox://styles/arielseidman/ckg6qqsp14xft19qlhbo9rvor';
};

export const MaxTilesSelected = 6;
export const ShortestDistanceBetweenTriangles = 0.0005;

export const DEFAULT_COORDS = {
  AZ: [-112.07425099083105, 33.448480891917406],
  SF: [-122.431297, 37.773972],
  LOCATION_MONITORING: [-101.27, 39.43],
};

export const valueScaledOnZoom = (value: number) => {
  return [
    'interpolate',
    ['exponential', 2],
    ['zoom'],
    10,
    ['*', value, ['^', 2, -6]],
    24,
    ['*', value, ['^', 2, 8]],
  ];
};

export const getFirstLabelId = (map: Map) => {
  const layers = map.getStyle().layers || [];
  let firstLabelId;
  for (const layer of layers) {
    if (layer?.id.includes('label')) {
      firstLabelId = layer.id;
      break;
    }
  }
  return firstLabelId;
};

export const centerMap = (
  map: Map,
  router: NextRouter,
  offset?: [number, number],
) => {
  let routerLat, routerLon, routerZoom;
  if (router.query?.center) {
    [routerLat, routerLon, routerZoom] = router.query.center
      .toString()
      .split(',')
      .map((num, i) => (i < 2 ? Number(Number(num).toFixed(6)) : Number(num)));
    updateMap(map, routerLat, routerLon, routerZoom, offset);
  }
};

export const updateMap = (
  map: Map,
  lat: number,
  lon: number,
  zoom: number,
  offset: [number, number] = [0, 0],
  speed = 1.2,
  bbox?: LngLatBoundsLike,
) => {
  const offsetCoords: PointLike = offset ?? [0, 0];
  if (isNaN(lon) || isNaN(lat) || isNaN(zoom)) return;
  const options = {
    offset: offsetCoords,
    speed,
  };
  if (bbox) {
    map.fitBounds(bbox, {
      padding: 10,
      ...options,
    });
  } else {
    map.flyTo({
      center: [lon, lat],
      zoom,
      ...options,
    });
  }
};

export const DEFAULT_MAP_TYPE = 'light';

export const getMapStyle = (type?: IMapType) => {
  let style;
  const BASE_ACCOUNT = 'mapbox://styles/arielseidman/';
  switch (type) {
    case 'light':
      style = 'clz0317to00ek01px3nds9wpo';
      break;
    case 'satellite':
      style = 'cl8x1mfz300am15qlyhvihs0a';
      break;
    case 'aiTrainers':
      style = 'cli4zfxpj00wa01r53ivd8cie';
      break;
    case 'valve':
      style = 'clix6511k00pf01odfk9uh77p';
      break;
    case 'location-monitoring':
      style = 'clq1gyyxm00kv01r7e2hb9q3l';
      break;
    case 'coverage':
      style = 'clyj3gdw0010v01rbeg7h5ie7';
      break;
    case 'dark':
    default:
      style = 'clz07hg1g00ew01r72k4x63mr';
      break;
  }
  return BASE_ACCOUNT + style;
};

interface LABEL_LAYERS {
  [key: string]: string[];
}

export const LABEL_LAYERS: LABEL_LAYERS = {
  //Dark and light placeholders below are values from map?.getStyle()?.name, These are used to distinguish between the two styles
  dark: [
    'road-label',
    'road-number-shield',
    'road-exit-shield',
    'ferry-aerialway-label',
    'airport-label',
    'settlement-subdivision-label',
    'settlement-minor-label',
    'settlement-major-label',
    'state-label',
    'country-label',
  ],

  light: [
    'road-label',
    'road-number-shield',
    'road-exit-shield',
    'ferry-aerialway-label',
    'airport-label',
    'settlement-subdivision-label',
    'settlement-minor-label',
    'settlement-major-label',
    'state-label',
    'country-label',
  ],
  satellite: [
    'road-label',
    'road-number-shield',
    'road-exit-shield',
    'ferry-aerialway-label',
    'airport-label',
    'settlement-subdivision-label',
    'settlement-minor-label',
    'settlement-major-label',
    'state-label',
    'country-label',
  ],
};

export const transformRequest: any = (
  url: string,
  resourceType: ResourceType,
) => {
  if (isMapboxURL(url)) {
    return transformMapboxUrl(url, resourceType, getMapboxAccessToken());
  }

  return { url };
};

export function calculateAzimuth(coord1: number[], coord2: number[]) {
  const lat1 = toRadians(coord1[1]);
  const lat2 = toRadians(coord2[1]);
  const lonDiff = toRadians(coord2[0] - coord1[0]);

  const y = Math.sin(lonDiff) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lonDiff);

  let azimuth = toDegrees(Math.atan2(y, x));

  // Convert the output to be between 0 and 360
  if (azimuth < 0) {
    azimuth += 360;
  }

  return azimuth;
}

// Helper functions for degree-radian conversions
export function toRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

export function toDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}

export interface GNSS {
  lat: number;
  lon: number;
  t: number;
}

export const getBearingFromGNSS = (data: GNSS[]) => {
  const { lat, lon } = data[0];
  const { lat: lat2, lon: lon2 } = data[data.length - 1];
  const bearing = calculateAzimuth([lon, lat], [lon2, lat2]);
  return bearing;
};

export const getBearingFromMetadata = (
  imageData: GNSS[],
  backupData: GNSS[],
  initialAzimuth: number,
) => {
  if (imageData && imageData.length >= 2) {
    return getBearingFromGNSS(imageData);
  }
  if (backupData && backupData.length >= 2) {
    return getBearingFromGNSS(backupData);
  }
  return initialAzimuth;
};

export const getAppleMapScaleOnZoom = (zoom: number) => {
  const scale = zoom - 18;
  if (scale <= 0) {
    return 1;
  }
  return (
    1 +
    0.04781936 * Math.pow(scale, 4) -
    0.11901621 * Math.pow(scale, 3) +
    0.52571882 * Math.pow(scale, 2) +
    0.52727511 * scale +
    0.01084327
  );
};

export const calculateDistance = (point1: IPoint, point2: IPoint): number => {
  return Math.sqrt(
    Math.pow(point1.lat - point2.lat, 2) + Math.pow(point1.lon - point2.lon, 2),
  );
};

export const getGroupedBounds = (
  predictionPoint: IPoint,
  points: IPoint[],
  limit = 3,
): [LngLatLike, LngLatLike] => {
  if (points.length === 0) {
    return [
      [predictionPoint.lon, predictionPoint.lat],
      [predictionPoint.lon, predictionPoint.lat],
    ];
  }

  const sortedPoints = Object.assign([], points).sort(
    (a, b) =>
      calculateDistance(predictionPoint, a) -
      calculateDistance(predictionPoint, b),
  );

  const nearestPoints: IPoint[] = sortedPoints;
  if (limit) {
    // use only the closest N limit points + the prediction point
    nearestPoints.splice(limit);
  }

  let bottomLeftLat = predictionPoint.lat;
  let bottomLeftLon = predictionPoint.lon;
  let topRightLat = predictionPoint.lat;
  let topRightLon = predictionPoint.lon;

  nearestPoints.forEach(point => {
    if (point.lat < bottomLeftLat) bottomLeftLat = point.lat;
    if (point.lon < bottomLeftLon) bottomLeftLon = point.lon;
    if (point.lat > topRightLat) topRightLat = point.lat;
    if (point.lon > topRightLon) topRightLon = point.lon;
  });

  return [
    [bottomLeftLon, bottomLeftLat],
    [topRightLon, topRightLat],
  ];
};

interface Point {
  lat: number;
  lon: number;
}
export function latLonDistance(point1: Point, point2: Point, accuracy = 0.01) {
  return GeoLib.getPreciseDistance(
    { latitude: point1.lat, longitude: point1.lon },
    { latitude: point2.lat, longitude: point2.lon },
    accuracy,
  );
}

export function getBounds(
  coords: [number, number][],
): [LngLatLike, LngLatLike] {
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  for (const [lng, lat] of coords) {
    if (lat < minLat) minLat = lat;
    if (lat > maxLat) maxLat = lat;
    if (lng < minLng) minLng = lng;
    if (lng > maxLng) maxLng = lng;
  }

  return [
    { lon: minLng, lat: minLat },
    { lon: maxLng, lat: maxLat },
  ];
}

export const getPixelsPerMeterAtLatitude = (latitude: number, zoom: number) => {
  const EARTH_RADIUS = 6378137;
  const latitudeRadians = (latitude * Math.PI) / 180;

  const circumferenceAtLatitude =
    2 * Math.PI * EARTH_RADIUS * Math.cos(latitudeRadians);

  const totalPixels = 256 * Math.pow(2, zoom);
  const pixelsPerMeter = totalPixels / circumferenceAtLatitude;

  return pixelsPerMeter;
};

export const getDotColor = (index: number) => {
  const colors = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF',
    '#800000',
    '#008080',
    '#800080',
    '#FFA500',
    '#A52A2A',
    '#008000',
    '#FFC0CB',
    '#800080',
    '#000080',
    '#808080',
  ];

  return colors[index % colors.length];
};

export const getCenter = (locations: ScoutLocation[]) => {
  const features = locations.map(location => turf.feature(location.geojson));
  const featureCollection = turf.featureCollection(features);
  const center = turf.center(featureCollection);

  return center.geometry.coordinates as LngLatLike;
};

export const getBoundsFromLocations = (locations: ScoutLocation[]) => {
  const features = locations.map(location => turf.feature(location.geojson));
  const featureCollection = turf.featureCollection(features);
  const bbox = turf.bbox(featureCollection);

  return bbox as LngLatBoundsLike;
};

export const arePointsEqual = (prevPoints: IPoints, nextPoints: IPoints) => {
  if (!prevPoints || !nextPoints) {
    return false;
  }
  if (prevPoints.length !== nextPoints.length) {
    return false;
  }

  for (let i = 0; i < prevPoints.length; i++) {
    const prevPoint = prevPoints[i];
    const nextPoint = nextPoints.find(
      point =>
        point.lat === prevPoint.lat &&
        point.lon === prevPoint.lon &&
        point.image === prevPoint.image,
    );

    if (!nextPoint) {
      return false;
    }
  }

  return true;
};
