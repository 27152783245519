import { createTheme, Theme } from '@mui/material';
import { globalSx } from './globalSx';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface TypographyVariants {
    secondary: React.CSSProperties;
    special: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    special?: React.CSSProperties;
  }
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 479,
    md: 767,
    lg: 991,
    xl: 1200,
  },
};

export const palette = {
  azure: {
    100: '#F0F6FF',
    400: '#91BCFD',
    500: '#619EFA',
    600: '#3D87F7',
    700: '#2F7BED',
    800: '#1D67D6',
    900: '#1E58AE',
    1000: '#1E498A',
  },
  neutral: {
    0: '#FFFFFF',
    100: '#F8F9FC',
    200: '#F1F4F9',
    300: '#E1E7EF',
    400: '#CBD4E1',
    500: '#94A2B8',
    600: '#5F6E85',
    700: '#3F454D',
    800: '#23282F',
    900: '#1B2027',
    1000: '#0A0E14',
  },
  tropicalIndigo: {
    100: '#F6F5FF',
    400: '#BFB4FD',
    500: '#9A89FA',
    600: '#8570FA',
    700: '#6D54F7',
    800: '#4F35DE',
    900: '#3721B5',
    1000: '#2F1D95',
  },

  electricBlue: {
    100: '#F1FDFE',
    400: '#A6F3FC',
    500: '#72EBF8',
    600: '#43DEEF',
    700: '#2DCEE0',
    800: '#25ABBA',
    900: '#1B8B98',
    1000: '#137B86',
  },

  pumpkin: {
    100: '#FEF6F1',
    400: '#FCC8A6',
    500: '#F8A872',
    600: '#EF8843',
    700: '#E0752D',
    800: '#BA6125',
    900: '#984D1B',
    1000: '#864113',
  },

  cinnabarDanger: {
    100: '#FEF3F1',
    400: '#FCB3A6',
    500: '#F88672',
    600: '#EF5D43',
    700: '#E0482D',
    800: '#BA3C25',
    900: '#982E1B',
    1000: '#862413',
  },

  veridianSuccess: {
    100: '#EDFDFA',
    400: '#6EE7CF',
    500: '#36D3B4',
    600: '#0EA486',
    700: '#08876E',
    800: '#04725C',
    900: '#06604E',
    1000: '#064C3E',
  },

  harvestGoldWarning: {
    100: '#FFF8EB',
    400: '#FDCA72',
    500: '#FBB53C',
    600: '#DB9212',
    700: '#B27409',
    800: '#96650F',
    900: '#80550D',
    1000: '#66460F',
  },

  // Pink
  secondary: {
    main: '#D433C4',
    light: '#B74D9D',
  },
  text: {
    primary: '#fff',
    light: '#DADADA',
    secondary: '#ffffffbf',
    disabled: '#ffffff99',
    primaryBlack: '#1D2633',
  },
};

// Default overrides
export const darkTheme: Theme = createTheme({
  palette,
  breakpoints,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '14px',
        },
        ...globalSx(palette),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { background: palette.neutral[800] },
      },
    },
    MuiDrawer: {
      styleOverrides: {},
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: { fontWeight: 'bold' },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.azure[600]}`,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: palette.azure[600],
          '&.Mui-selected, &:hover.Mui-selected': {
            backgroundColor: palette.azure[600],
            color: palette.neutral[0],
          },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            fieldSet: {
              borderRadius: '7px',
              border: '0px none',
            },
            backgroundColor: palette.azure[600],
            borderRadius: '7px',
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          // fontSize: '12px',
          backgroundColor: 'transparent',
          paddingTop: '4px',
          paddingBottom: '4px',
          ':hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
          },
        },
        icon: {
          color: palette.azure[600],
        },
      },
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            fontSize: '18px',
            '& fieldset': {
              borderRadius: '7px',
              border: '0px none',
            },
            '.MuiSelect-icon': {
              color: palette.neutral[0],
            },
            backgroundColor: palette.azure[600],
            borderRadius: '7px',
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: { maxWidth: '90%' },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: { color: palette.neutral[0] },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ':hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.azure[600],
          },
        },
        notchedOutline: {
          borderColor: palette.neutral[1000],
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: palette.neutral[0] },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: palette.neutral[1000],
          '.MuiMenuItem-root': {
            '&:hover': {
              color: palette.azure[600],
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: palette.neutral[1000],
              color: palette.azure[600],
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '100px',
          ':hover': { background: palette.azure[500] },
        },
      },
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: palette.neutral[0],
            paddingLeft: '32px',
            paddingRight: '32px',
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: palette.azure[600],
            fontWeight: 'bold',
            ':hover': {
              color: palette.azure[500],
              background: 'transparent',
            },
            ':disabled': {
              color: palette.azure[500],
              opacity: 0.35,
              borderColor: palette.azure[500],
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontWeight: 'bold',
            color: palette.azure[600],
            paddingLeft: '32px',
            paddingRight: '32px',
            borderColor: palette.azure[600],
            ':hover': { color: palette.azure[500] },
            ':disabled': {
              color: palette.azure[500],
              opacity: 0.35,
              borderColor: palette.azure[500],
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: '100%',
          borderColor: palette.neutral[800],
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '@media (min-width: 479px)': {
            top: '76px !important',
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: { fontSize: '16px' },
        root: {
          backgroundColor: palette.veridianSuccess[500],
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Lato',
    fontSize: 16,
    title: {
      fontFamily: 'Montserrat',
      fontSize: 48,
      fontWeight: 'bold',
      [`@media (max-width: ${breakpoints.values.xl}px)`]: {
        fontSize: 42,
      },
      [`@media (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 32,
      },
      [`@media (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: 24,
      },
      color: palette.text.primary,
    },
    body1: {
      fontSize: 14,
      color: palette.text.primary,
    },
    body2: {
      fontSize: 12,
      color: palette.text.primary,
    },
    special: {
      fontFamily: 'Inconsolata',
    },
    h1: {
      fontSize: 28,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    h2: {
      fontSize: 24,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    h3: {
      fontSize: 21,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    h4: {
      fontSize: 18,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    h5: {
      fontSize: 16,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    h6: {
      fontSize: 14,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    subtitle1: {
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 'bold',
      color: palette.text.primary,
    },
  },
});
