import {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { getDefaultFeatureFlags } from '@/api/featureFlags';
import { IFeatureFlags } from '@/types/featureFlags';
import { ILaunchDarklyUser } from '@/types/user';
import { useUser } from './useUser';

interface IContext {
  featureFlags: IFeatureFlags;
}

const FeatureFlagsContext = createContext<IContext | Record<string, never>>({});

let cachedFeatureFlags: any;

export const getCachedFlags = () => {
  return cachedFeatureFlags;
};

const FeatureFlagsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlags>({});
  const { user } = useUser();

  useEffect(() => {
    const tryGetDefaultFeatureFlags = async () => {
      const launchDarklyUser: ILaunchDarklyUser = {
        name: user?.username,
        custom: {
          platform: 'web',
        },
      };
      try {
        const featureFlags = await getDefaultFeatureFlags(launchDarklyUser);
        setFeatureFlags(featureFlags);
      } catch (error) {
        console.log('Failure retrieving default feature flags: ', error);
      }
    };

    tryGetDefaultFeatureFlags();
  }, [user?.username]);

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within the provider');
  }
  return context;
};

export { FeatureFlagsProvider, useFeatureFlags };
