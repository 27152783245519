import React, { useState } from 'react';
import { toast } from 'sonner';
import Router, { useRouter } from 'next/router';
import Link from 'next/link';
import { getStripeCheckoutUrl } from '@/api/stripe';
import { redirectTo } from '@/utils/url';
import { Button } from '@/components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/shadcn/dialog';
import { RadioGroup, RadioGroupItem } from '@/components/shadcn/radioGroup';
import { Label } from '@/components/shadcn/label';
import { Input } from '@/components/shadcn/input';
import * as Toast from '@/components/ui/toast/Toast';
import { useMapCredits } from '@/hooks/useMapCredits';

const MAP_CREDIT_PRICE_ID = 'price_1OLDmyAEnA9fV1kb4KOQZzWA';

export default function BuyCreditsModal() {
  const [value, setValue] = useState('1000');
  const [customQuantity, setCustomQuantity] = useState('');

  const { creditsModalOptions, setCreditsModalOptions } = useMapCredits();

  const quantity = value === 'custom' ? Number(customQuantity) : Number(value);

  const handleChange = (value: string) => {
    setCustomQuantity('');
    setValue(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    // Allow only numeric input
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setCustomQuantity(value);
    }
  };

  const initCheckout = async (quantity: number) => {
    const tryGetStripeCheckoutUrl = async () => {
      const response = await getStripeCheckoutUrl(
        quantity,
        MAP_CREDIT_PRICE_ID,
        creditsModalOptions.redirectionUrl ?? Router.asPath,
      );

      if ('error' in response || !response.url) {
        toast.custom(t => (
          <Toast.Default
            title="Stripe Checkout"
            description="Oops! We can't process your transaction right now."
            buttons={[
              {
                label: 'Contact',
                onClick: () => Router.push('mailto:sales@hivemapper.com'),
                variant: 'default',
                size: 'fw',
              },
              {
                label: 'Dismiss',
                onClick: () => toast.dismiss(t),
                variant: 'secondary',
                size: 'fw',
              },
            ]}
          />
        ));
        return;
      }

      redirectTo(response.url);
    };

    tryGetStripeCheckoutUrl();
  };

  return (
    <>
      <Dialog
        open={creditsModalOptions.isVisible}
        onOpenChange={(open: boolean) => {
          setCreditsModalOptions(prevState => ({
            ...prevState,
            isVisible: open,
          }));
        }}>
        <DialogContent className="w-[280px]">
          <DialogHeader>
            <DialogTitle className="mb-4">Purchase Map Credits</DialogTitle>
            <DialogDescription>
              <div className="mb-4 px-10">
                50 Map Credits buys 1 KM of map data per week
              </div>
              <RadioGroup
                className="flex flex-col text-left"
                value={value}
                defaultValue="1000"
                onValueChange={value => handleChange(value)}>
                <div className="flex items-center gap-3 py-3">
                  <RadioGroupItem
                    value="1000"
                    id="r1"
                    className="data-[state=checked]:border-azure-700"
                  />
                  <Label htmlFor="r1">1,000 ($20)</Label>
                </div>
                <div className="flex items-center gap-3 py-3">
                  <RadioGroupItem
                    value="5000"
                    id="r2"
                    className="data-[state=checked]:border-azure-700"
                  />
                  <Label htmlFor="r2">5,000 ($100)</Label>
                </div>
                <div className="flex items-center gap-3 py-3">
                  <RadioGroupItem
                    value="25000"
                    id="r3"
                    className="data-[state=checked]:border-azure-700"
                  />
                  <Label htmlFor="r3">25,000 ($500)</Label>
                </div>
                <div className="flex items-center gap-3 py-3">
                  <RadioGroupItem
                    value="custom"
                    id="r4"
                    className="data-[state=checked]:border-azure-700"
                  />
                  <Label htmlFor="r4">Custom Amount</Label>
                </div>
              </RadioGroup>
              <Input
                className="text-xs"
                value={customQuantity}
                onChange={handleInputChange}
                onFocus={() => {
                  setValue('custom');
                }}
                placeholder="Minimum purchase 500 ($10.00+)"
                type="number"
              />
              <div className="mt-4">
                By purchasing Map Credits, you agree to our{' '}
                <Link href="/tos" className="underline">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link href="/privacy" className="underline">
                  Privacy Policy
                </Link>
                .
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="dark"
              onClick={() => {
                setCreditsModalOptions(prevState => ({
                  ...prevState,
                  isVisible: false,
                }));
              }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                initCheckout(quantity);
              }}
              disabled={quantity < 500}>
              Checkout
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
