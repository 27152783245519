import resolveConfig from 'tailwindcss/resolveConfig';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import chroma from 'chroma-js';
import tailwindConfig from '../tailwind.config.ts';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const fullConfig = resolveConfig(tailwindConfig);

export const {
  theme: { colors, screens },
} = fullConfig;

const getCssVariableValue = (variable: string) => {
  return getComputedStyle(document.body).getPropertyValue(variable).trim();
};

export const hslStringToHex = (hslString: string) => {
  try {
    const variableMatch = hslString.match(/var\((--[\w-]+)\)/);

    if (!variableMatch) {
      throw new Error('Invalid HSL string');
    }

    const variableValue = getCssVariableValue(variableMatch[1]);
    const [h, s, l] = variableValue.replaceAll('%', '').split(' ').map(Number);
    return chroma.hsl(h, s / 100, l / 100).hex();
  } catch (error) {
    console.log('Error converting HSL string to HEX', error);
    return '#000000';
  }
};
