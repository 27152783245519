import { ParsedUrlQuery } from 'querystring';
import Router, { NextRouter } from 'next/router';
import * as storage from '@/utils/storage';

export const DEFAULT_CLIENT_PATH = '/network/coverage';

export const openLinkInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const redirectTo = (url: string) => {
  if (window && url.includes('://')) {
    window.location.href = url;
  } else {
    Router.push(url);
  }
};

export const copyTextToClipboard = (text: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).catch(function () {
      console.log('copy error');
    });
  }
};

export const buildQueryParamString = (
  urlQuery: ParsedUrlQuery,
  exclude?: string,
) => {
  let params = '';
  for (const property in urlQuery) {
    if (property !== exclude) {
      params += `${params ? '&' : '?'}${property}=${urlQuery[property]}`;
    }
  }
  return params;
};

export const buildUrlParamString = (params: Record<string, string>) => {
  const validParams = Object.entries(params)
    .filter(([_, value]) => !!value)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(
          v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`,
        );
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .flat()
    .join('&');

  return validParams ? `?${validParams}` : '';
};

export const getQueryParam = (
  param: string,
  router?: NextRouter,
  andLocalStorage?: boolean,
): string => {
  let queryParam = router?.query?.[param];
  if (!queryParam && andLocalStorage) {
    const pageName = window.location.pathname;
    queryParam = storage.get(`${pageName}_query_${param}`);
  }
  return (queryParam || '').toString();
};

export const updateQueryParam = (
  param: string,
  newValue: string,
  andLocalStorage?: boolean,
  saveRouterHistory = true,
) => {
  const url = new URL(window.location.href);
  if (newValue) {
    url.searchParams.set(param, newValue);
  } else {
    url.searchParams.delete(param);
  }

  if (saveRouterHistory) {
    Router.push(url, undefined, { shallow: true });
  } else {
    Router.replace(url, undefined, { shallow: true });
  }

  if (andLocalStorage) {
    const pageName = window.location.pathname;
    storage.set(`${pageName}_query_${param}`, newValue);
  }
};

export const stripQueryParamsFromPath = (path: string) => {
  return path.replace(/\?.*$/, '');
};

export const ExternalUrls = {
  PurchaseDashcam:
    'https://hivemapper.myshopify.com/collections/frontpage/products/blackvue-dr900s-1ch-4k-dashcam-1-channel-128gb-sd-card',
  Docs: 'https://docs.hivemapper.com/',
  ContributeGuide: 'https://docs.hivemapper.com/start-mapping/driving',
  ContributeWithDashcam: 'https://docs.hivemapper.com/contribute/dashcam',
  MapApi: 'https://docs.hivemapper.com/main-concepts/map-image-api',
  Upload: 'https://docs.hivemapper.com/contribute/dashcam/5-upload-content',
  UploadVideo: 'https://www.youtube.com/watch?v=CqX5VZJXGe8',
  PreOrder: 'https://shop.hivemapper.com/',
  CryptoToken: 'https://coinmarketcap.com/currencies/solana/',
  HivemapperWeb: 'https://hivemapper.com/',
  Dashcam: 'https://hivemapper.com/hivemapper-dashcam',
  FAQ: 'https://docs.hivemapper.com/resources/frequently-asked-questions',
  HowItWorks: 'https://hivemapper.com/mapping-network#introduction',
  Pricing: 'https://hivemapper.com/pricing',
  Honey:
    'https://solscan.io/token/4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy#analysis',
  Mapbox: 'https://www.mapbox.com/about/maps',
  OSM: 'https://www.openstreetmap.org/copyright',
  ImproveMap: 'https://www.mapbox.com/map-feedback',
  GithubODC: 'https://github.com/hivemapper/odc',
  BuyADashcam:
    'https://shop.hivemapper.com/?utm_source=salebutton&utm_medium=organic&utm_campaign=salebutton&utm_id=1212023',
  AITrainerDocs: 'https://docs.hivemapper.com/start-mapping/train-map-ai',
  BeeDashcamStore: 'https://bee.hivemapper.com/',
  BeeLegalPlacards:
    'https://bee.hivemapper.com/onboarding-hivemapper-dashcam#legal-placards',
  MapWithPhone: 'https://docs.hivemapper.com/start-mapping/map-with-phone',
  MapDataConsole: 'https://docs.hivemapper.com/use-the-map/console',
  Scout: 'https://docs.hivemapper.com/use-the-map/scout',
  Product: 'https://hivemapper.com/product',
  VeraSafeWebsite:
    'https://verasafe.com/public-resources/contact-data-protection-representative',
  ProtectionAndPrivacy:
    'https://docs.hivemapper.com/welcome/data-protection-and-privacy',
  ScoutHostedService:
    'https://docs.hivemapper.com/use-the-map/scout/scout-hosted-service',
  MapProducts: 'https://hivemapper.com/tos/map-products/#introduction',
};
