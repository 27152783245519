import { keyframes } from '@mui/material';

// Avoid writing global styles when possible
export const globalSx = (palette: any) => ({
  'html, body > div:first-child, div#__next': {
    height: '100%',
  },
  body: {
    background: palette.neutral[1000],
    height: '100%',
  },
  '[data-tippy-root]': {
    zIndex: '1102 !important',
    '.map-popover': {
      zIndex: '1103 !important',
      '.row__name': {
        display: 'none',
      },
    },
  },
  /* Disable freshview gif title */
  '.map-popover .row__name': {
    display: 'none',
  },

  '::-webkit-scrollbar': {
    width: 0,
    display: 'none',
  },

  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },

  '.swiper-pagination-panel': {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    left: '50% !important',
    transform: 'translateX(-50%) !important',
  },

  '.swiper-pagination-panel-horizontal': {
    display: 'contents',
    width: 'auto !important',
    left: '50% !important',
    transform: 'translateX(-50%) !important',
    position: 'fixed !important',
    '@media (max-width: 477px)': {
      bottom: '68px !important',
    },
  },

  '.swiper-pagination-horizontal': {
    width: 'auto !important',
    bottom: '112px !important',
    left: '50% !important',
    transform: 'translateX(-50%) !important',
    position: 'fixed !important',
    '@media (max-width: 477px)': {
      bottom: '68px !important',
    },
  },

  '.swiper-pagination-bullet': {
    width: '0.70rem !important',
    height: '0.70rem !important',
    background: '#a1a1a1 !important',
    zIndex: '9999 !important',
    cursor: 'pointer !important',
    position: 'relative !important',
  },

  '.swiper-pagination-bullet-active': {
    background: '#91bcfd !important',
  },

  MsOverflowStyle: 'none' /* Internet Explorer 10+ */,
  ScrollbarWidth: 'none' /* Firefox */,
});

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

export const scaledFadeIn = keyframes`
from {
  transform: scale(0.5);
  opacity: 0;
}
to {
  transform: scale(1);
  opacity: 1;
}
`;
