import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { IUser } from '@/types/user';

interface IContext {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
}

interface UserProps {
  children: React.ReactNode;
  user: IUser | null;
}

const UserContext = createContext<IContext | null>(null);

const UserProvider: React.FC<UserProps> = ({ children, user: initialUser }) => {
  const [user, setUser] = useState<IUser | null>(initialUser);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within the UserProvider');
  }

  return context;
};

export { UserProvider, useUser };
