export const STORAGE_KEYS = {
  QA_REGION_ID: 'qa-region-id',
  QA_COLLECTION_ID: 'qa-collection-id',
  CREATED_REGIONS: 'created-regions',
  PREORDER_SEEN: 'preorder-seen',
  MAP_TYPE: 'map-type',
  LATEST_LOCATION: 'latest-location',
};

export const set = (key: string, data: any, expiredInMinutes?: number) => {
  const storageItem = {
    data,
    expiredIn: expiredInMinutes
      ? new Date(new Date().getTime() + expiredInMinutes * 60000)
      : null,
  };
  window.localStorage.setItem(key, JSON.stringify(storageItem));
};

export const get = (key: string) => {
  const storageItem = JSON.parse(window.localStorage.getItem(key) || 'null');
  if (
    storageItem &&
    (!storageItem.expiredIn ||
      new Date(storageItem.expiredIn).getTime() > Date.now())
  ) {
    return storageItem.data;
  } else {
    return null;
  }
};
