import { apiCall } from '@/utils/api';
import { IFeatureFlags } from '@/types/featureFlags';
import { ILaunchDarklyUser } from '@/types/user';

let cachedFeatureFlags: IFeatureFlags;

export const getDefaultFeatureFlags = async (
  launchDarklyUser: ILaunchDarklyUser,
): Promise<IFeatureFlags> => {
  const params = new URLSearchParams({
    platform: launchDarklyUser.custom.platform,
  });

  const url = `${process.env.NEXT_PUBLIC_API}/defaultflags?${params}`;

  try {
    const { flags } = await apiCall(url, { method: 'GET' });
    cachedFeatureFlags = flags;
    return flags;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getCachedFeatureFlags = () => {
  return cachedFeatureFlags || {};
};
