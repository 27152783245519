import { NextPageContext } from 'next';
import { apiCall } from '@/utils/api';

export const getStripeCheckoutUrl = async (
  quantity: number,
  priceId: string,
  returnUrl: string,
) => {
  const route = returnUrl.startsWith('/') ? returnUrl.substring(1) : returnUrl;
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/stripe/create-checkout-session`,
      {
        method: 'POST',
      },
      {
        quantity,
        priceId,
        successUrl: `${window.location.protocol}//${window.location.host}/${route}`,
        cancelUrl: `${window.location.protocol}//${window.location.host}/${route}`,
        mode: 'payment',
        mapCredits: quantity,
      },
    );
    return response;
  } catch (error) {
    return { error };
  }
};

export const completeStripeCheckout = async (sessionId: string) => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/stripe/complete/${sessionId}`,
      {
        method: 'POST',
      },
    );
    return response;
  } catch (error) {
    return { error };
  }
};

export const expireStripeCheckout = async (sessionId: string) => {
  try {
    const response = await apiCall(
      `${process.env.NEXT_PUBLIC_API}/stripe/expire/${sessionId}`,
      {
        method: 'POST',
      },
    );
    return response;
  } catch (error) {
    return { error };
  }
};

export const getAdminMapCreditData = async (
  startDate: Date,
  endDate: Date,
  req?: NextPageContext['req'],
) => {
  const url = `${
    process.env.NEXT_PUBLIC_API
  }/admin/mapCreditsData?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  try {
    const response = await apiCall(url, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};
