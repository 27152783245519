export interface ApiError {
  error: ApiErrorEnum;
}

export enum ApiErrorEnum {
  Forbidden = 'Forbidden',
  TooManyRequests = 'Too many requests',
  NoContent = 'No content',
  Captcha = 'Must complete captcha to continue',
  InvalidFeature = 'Invalid feature, please click on Not Sure',
}

export interface ErrorResponse {
  error: any;
}
