import { CacheProvider, EmotionCache } from '@emotion/react';
import { AppContext, AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import Page from '@/components/layout/Page';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@/styles/globals.css';
import '@/styles/fonts.css';
import '@/styles/maplibre.css';
import UserStore, { hasSessionCookie } from '@/utils/session';
import { getSession } from '@/api/auth';
import { UserProvider } from '@/hooks/useUser';
import { MapTypeProvider } from '@/hooks/useMapType';
import Sentry from '@/components/analytics/Sentry';
import { Toaster } from '@/components/shadcn/sonner';
import { IUser } from '@/types/user';
import { MapCreditProvider } from '@/hooks/useMapCredits';
import createEmotionCache from '@/legacy/utils/createEmotionCache';
import { darkTheme } from '@/legacy/themes/darkTheme';
import { FeatureFlagsProvider } from '@/hooks/useFeatureFlags';
import { ToasterProvider } from '@/hooks/useToaster';
import BuyCreditsModal from '@/components/ui/console/BuyCreditsModal';

const clientSideEmotionCache = createEmotionCache();

interface AppExtendedProps extends AppProps {
  user: IUser;
  emotionCache?: EmotionCache;
}

export default function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppExtendedProps) {
  const { user, ...rest } = pageProps;

  return (
    <>
      <UserProvider user={user}>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={darkTheme}>
            <FeatureFlagsProvider>
              <MapTypeProvider>
                <MapCreditProvider>
                  <ToasterProvider>
                    <Sentry />
                    <Page>
                      <Component {...rest} />
                      <Toaster />
                      <BuyCreditsModal />
                    </Page>
                  </ToasterProvider>
                </MapCreditProvider>
              </MapTypeProvider>
            </FeatureFlagsProvider>
          </ThemeProvider>
        </CacheProvider>
      </UserProvider>
    </>
  );
}

App.getInitialProps = async (appContext: AppContext) => {
  let user: IUser | null = null;
  const { ctx } = appContext;

  if (typeof window === 'undefined') {
    const cookies = ctx.req?.headers.cookie;

    if (hasSessionCookie(cookies)) {
      user = await getSession(ctx.req);
    }

    UserStore.set(user);
  }

  return { pageProps: { user } };
};
