import { useEffect } from 'react';

interface SeoProps {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
}

const useSeo = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
}: SeoProps) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }

    if (description) {
      updateMetaTag('name', 'description', description);
    }

    if (keywords) {
      updateMetaTag('name', 'keywords', keywords);
    }

    if (ogTitle) {
      updateMetaTag('property', 'og:title', ogTitle);
    }
    if (ogDescription) {
      updateMetaTag('property', 'og:description', ogDescription);
    }
    if (ogImage) {
      updateMetaTag('property', 'og:image', ogImage);
    }

    if (twitterTitle) {
      updateMetaTag('name', 'twitter:title', twitterTitle);
    }
    if (twitterDescription) {
      updateMetaTag('name', 'twitter:description', twitterDescription);
    }
    if (twitterImage) {
      updateMetaTag('name', 'twitter:image', twitterImage);
    }
  }, [
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogImage,
    twitterTitle,
    twitterDescription,
    twitterImage,
  ]);

  const updateMetaTag = (
    attrName: string,
    attrValue: string,
    content: string,
  ) => {
    let metaTag = document.querySelector<HTMLMetaElement>(
      `meta[${attrName}="${attrValue}"]`,
    );

    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.setAttribute(attrName, attrValue);
      document.head.appendChild(metaTag);
    }

    metaTag.content = content;
  };
};

export default useSeo;
