import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { defaultSeo, seo } from '@/seo/seo';
import GoogleTagManager from '@/components/analytics/GoogleTagManager';
import useSeo from '@/hooks/useSeo';

interface Props {
  children?: React.ReactNode;
}

const PRODUCTION_DOMAIN = 'https://hivemapper.com';

export default function Page({ children }: Props) {
  const router = useRouter();
  const { title, description, keywords, image } =
    seo[router.asPath] || defaultSeo;

  useSeo({
    title,
    description,
    keywords,
    ogTitle: title,
    ogDescription: description,
    ogImage: image,
    twitterTitle: title,
    twitterDescription: description,
    twitterImage: image,
  });

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      window.gtag('config', `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`, {
        page_path: url,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta property="og:title" content={title} />
        <meta
          property="og:url"
          content={`${PRODUCTION_DOMAIN}${router.asPath}`}
        />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:url"
          content={`${PRODUCTION_DOMAIN}${router.asPath}`}
        />
        <meta property="og:site_name" content="Hivemapper" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hivemapper" />
        <meta name="twitter:PRODUCTION_DOMAIN" content="${base}" />
        <meta
          name="facebook-PRODUCTION_DOMAIN-verification"
          content="eauxs979ttksaqj7dosv713u0rxvuz"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <GoogleTagManager />
      {children}
    </>
  );
}
