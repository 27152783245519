import {
  useState,
  createContext,
  useContext,
  useCallback,
  PropsWithChildren,
} from 'react';
import { useIsomorphicLayoutEffect } from '@/utils/helpers';
import * as storage from '@/utils/storage';
import { DEFAULT_MAP_TYPE } from '@/utils/map';
import { IMapType } from '@/types/map';

interface IContext {
  mapType: IMapType;
  setMapType: (type: IMapType) => void;
}

const MapTypeContext = createContext<IContext | Record<string, never>>({});

const MapTypeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [mapType, _setMapType] = useState<IMapType>(DEFAULT_MAP_TYPE);

  const setMapType = useCallback((type: IMapType) => {
    storage.set(storage.STORAGE_KEYS.MAP_TYPE, type);
    _setMapType(type);
  }, []);

  useIsomorphicLayoutEffect(() => {
    const mapType =
      storage.get(storage.STORAGE_KEYS.MAP_TYPE) || DEFAULT_MAP_TYPE;
    setMapType(mapType);
  }, [setMapType]);

  return (
    <MapTypeContext.Provider
      value={{
        mapType,
        setMapType,
      }}>
      {children}
    </MapTypeContext.Provider>
  );
};

const useMapType = () => {
  const context = useContext(MapTypeContext);
  if (context === undefined) {
    throw new Error('useMapType must be used within the MapTypeProvider');
  }
  return context;
};

export { MapTypeProvider, useMapType };
