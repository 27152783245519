import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/styles';

const inputVariants = cva(
  'flex h-9 w-full rounded border border-input px-3 py-1 outline-none transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-700 focus:outline-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-transparent text-sm',
        dark: 'bg-neutral-900 text-xs placeholder:text-neutral-700',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  asChild?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
